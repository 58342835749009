const defaults = {
  // Sidebar
  sidebarFixed: true,
  sidebarToggleMobile: false,
  sidebarToggle: false,
  sidebarHover: false,
  // Header

  headerFixed: true,
  headerSearchHover: false,

  // Page title

  pageTitleStyle: '',
  pageTitleBackground: '',
  pageTitleShadow: false,
  pageTitleBreadcrumb: false,
  pageTitleIconBox: true,
  pageTitleDescription: true,

  shortcuts: {},
};

export default defaults;
