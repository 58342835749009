import React, { Fragment } from 'react';
import { Typography } from '@material-ui/core';

import StatuspageWidget from 'src/components/StatuspageWidget';

const Footer = (props) => {
  return (
    <Fragment>
      <div className="app-footer--inner">
        <div className="app-footer--second">
          <div className="d-flex flex-gap-10">
            <div>
              Copyright © {new Date().getFullYear()} &nbsp;
              <a href="https://rmsincoroprated.com" title="RMS Inc">
                Lighthouse Management Services, Inc.
              </a>
            </div>
            <div>|</div>
            <Typography variant="caption">
              Version: {window.app.version}
            </Typography>
            <div>|</div>

            <StatuspageWidget />
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default Footer;
