import React, { Fragment, useEffect, useState } from 'react';
import { NavLink as RouterLink } from 'react-router-dom';
import { ArrowBack as ArrowBackIcon } from '@material-ui/icons';
import { Grid, Container, Card, CardContent, Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

import buildingsSvg from 'src/assets/images/illustrations/buildings.svg';
import logo from 'src/assets/images/logo.png';

import LoginForm from 'src/components/LoginForm';
import { Switch, Case } from 'src/components/Switch';

import useSnackbar from 'src/hooks/useSnackbar';

import CaptureEmail from './CaptureEmail';

const useStyles = makeStyles(() => ({
  container: {
    justifyContent: 'center',
  },
  wrapper: {
    background: `url(${buildingsSvg}) bottom no-repeat`,
  },
}));

const contactEmail = 'support@recalls123.com';

const Login = ({ location, history }) => {
  const { showSnackbar } = useSnackbar();
  const [credentials, setCredentials] = useState({});
  const [view, setView] = useState('Login');

  const classes = useStyles();

  const handleEmailRequirement = (payload) => {
    setCredentials(payload);
    setView('CaptureEmail');
  };

  useEffect(() => {
    if (location?.state?.message) {
      showSnackbar({ message: location.state.message, variant: 'success' });
      history.replace();
    }
  }, [location, showSnackbar, history]);

  return (
    <Fragment>
      <div className={`app-wrapper min-vh-100 ${classes.wrapper}`}>
        <div className="app-main flex-column">
          <Button
            size="large"
            variant="outlined"
            color="primary"
            component={RouterLink}
            className="btn-go-back"
            to="/"
          >
            <span className="btn-wrapper--icon">
              <ArrowBackIcon />
            </span>
            <span className="btn-wrapper--label">Back</span>
          </Button>
          <div className="app-content p-0">
            <div className="app-content--inner d-flex align-items-center">
              <div className="bg-composed-wrapper--content pb-5">
                <Container maxWidth="md">
                  <Grid container className={classes.container} spacing={5}>
                    <Grid
                      item
                      xs={12}
                      lg={7}
                      className="d-flex flex-column align-items-center"
                    >
                      <Card className="m-0 w-100 p-3">
                        <CardContent className="p-3">
                          <div className="text-center font-size-lg  text-black-50 mb-3">
                            <img
                              alt="Recalls 123"
                              className="w-43"
                              src={logo}
                            />
                          </div>
                          <div>
                            <Switch test={view}>
                              <Case value="Login">
                                <LoginForm
                                  className="mt-5"
                                  onEmailRequirement={handleEmailRequirement}
                                />
                              </Case>
                              <Case value="CaptureEmail">
                                <CaptureEmail credentials={credentials} />
                              </Case>
                            </Switch>
                          </div>
                          <div className="text-center mt-2">
                            <div className="mt-2">
                              <a href={`mailto:${contactEmail}`}>
                                {contactEmail}
                              </a>
                            </div>
                          </div>
                        </CardContent>
                      </Card>
                    </Grid>
                  </Grid>
                </Container>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};
export default Login;
