import { useState, useCallback, useEffect } from 'react';
import api from 'src/services/api';
import useIsFirstRender from './useIsFirstRender';

const useAPI = (request) => {
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState(undefined);
  const isFirstRender = useIsFirstRender();

  const fetchData = useCallback(
    async (params) => {
      setLoading(true);
      setError(null);

      try {
        const result = await request(api, params);
        setData(result);
      } catch (error) {
        setError(error);
      } finally {
        setLoading(false);
      }
    },
    [setData, setError, setLoading, request],
  );

  useEffect(() => {
    if (!isFirstRender) return;
    fetchData({});
  }, [fetchData, isFirstRender]);

  const refetch = useCallback(() => {
    // passing params to clear cache.
    fetchData({ _t: +new Date() });
  }, [fetchData]);

  const setState = useCallback(
    (incoming) => setData((current) => ({ ...current, ...incoming })),
    [],
  );

  return {
    error,
    loading,
    data,
    refetch,
    setState,
  };
};

export default useAPI;
